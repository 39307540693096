"use client";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { Box, InputBase, Stack } from "@mui/material";
import {
  ArrowUpwardOutlined as SendOffIcon,
  ArrowUpward as SendOnIcon,
  StopCircleOutlined as StopIcon,
  LockOutlined as LockedIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../../service/mobx";
import ButtonToggle from "../../Button/Toggle";

function Input({ locked }) {
  const [input, setInput] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const { agent, reader, user } = useStore();
  const pathname = usePathname();
  const disabled =
    agent.threadId === undefined ||
    ((pathname.startsWith("/docs") || pathname.startsWith("/read")) &&
      reader.viewing === "paper" &&
      agent.paperHasBeenRead === false);

  const submit = () => {
    if (locked) {
      locked();
    } else {
      setInput("");
      agent.chat(input);
    }
  };

  usePaper(pathname, setPlaceholder);

  return (
    <Stack
      spacing={0.5}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={theme => theme.shape.xl.round}
      sx={theme => ({
        pl: 2,
        pr: 1,
        py: 1,
        transition: theme.transitions.create("all"),
        bgcolor: "surface.container.lowest",
        "&:hover": {
          bgcolor: "surface.container.low"
        }
      })}
    >
      <InputBase
        fullWidth
        value={input}
        disabled={disabled}
        multiline={100 < input.length}
        maxRows={8}
        placeholder={placeholder}
        sx={theme => ({
          position: "relative",
          typography: "titleMd",
          color: "surface.on.color",
          ".Mui-disabled": {
            fontStyle: "italic",
            WebkitTextFillColor: theme.palette.secondary.on.container
          }
        })}
        inputProps={{ sx: { color: "surface.on.color" } }}
        onChange={event => setInput(event.target.value)}
        onKeyUp={event => {
          if (event.key === "Enter" && input) {
            submit();
          }
        }}
      />
      <Box alignSelf="flex-end">
        <ButtonToggle
          aria-label="Submit"
          disabled={agent.running === false && (disabled || input === "")}
          OffIcon={locked ? LockedIcon : SendOffIcon}
          OnIcon={agent.running ? StopIcon : locked ? LockedIcon : SendOnIcon}
          selected={
            (agent.running === false && (disabled || input === "")) === false
          }
          onClick={() => {
            if (agent.running) {
              const [{ runId }] = agent.history;

              fetch(
                `/api/agent?threadId=${user.profile.threadId}&runId=${runId}`,
                { method: "PATCH" }
              );
              agent.set.running(false);
            } else {
              submit();
            }
          }}
        />
      </Box>
    </Stack>
  );
}

export default observer(Input);

function usePaper(pathname, setSpeech) {
  const { agent, reader } = useStore();
  // const isDiscover = pathname === "/";
  const isPaperPage =
    pathname.startsWith("/docs/") || pathname.startsWith("/read/");

  useEffect(() => {
    if (isPaperPage) {
      if (agent.paperHasBeenRead) {
        setSpeech(
          "Have a question on " + reader.paperNode?.title?.split(":")[0] + "?"
        );
      } else if (reader.viewing === "paper") {
        setSpeech("Reading the paper for you...");
      } else {
        setSpeech("Ask Bytez about AI...");
      }
    } else {
      setSpeech("Ask Bytez about AI...");
    }
  }, [reader, setSpeech, isPaperPage, reader.viewing, agent.paperHasBeenRead]);
}

"use client";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { observer, useStore } from "../../service/mobx";
import Input from "./Input";
import Messages from "./Messages";
import { useFeatureGate } from "./ClientHooks";
import Suggestions from "./Input/Suggestions";

function AgentClient() {
  const { device, reader } = useStore();
  const locked = useFeatureGate();
  const [dynamicHeight, setDynamicHeight] = useState("100vh");

  // mobile devices treat vh dynamically, based on if address bars is rendered
  // to compensate, on mobile dynamically calculate height based on window.innerHeight
  useEffect(() => {
    if (device.isPhone && reader.whiteLabelled === false) {
      const updateHeight = () => {
        const headerHeight = 48; // Adjust this based on your layout
        const calculatedHeight = window.innerHeight - headerHeight;

        setDynamicHeight(`${calculatedHeight}px`);
      };

      // Set initial height
      updateHeight();

      // Update height on window resize
      window.addEventListener("resize", updateHeight, { passive: true });

      // Cleanup the event listener on component unmount
      return () => window.removeEventListener("resize", updateHeight);
    }
  }, [reader.whiteLabelled, device.isPhone]);

  return reader.whiteLabelled ? null : (
    <Box
      display="flex"
      flexDirection="column"
      mt={{ compact: "0 !important" }}
      pb={{ compact: 2, expanded: 0 }}
      pr={{ compact: 1, expanded: 0 }}
      pl={{ compact: 2, large: 3 }}
      width={{ compact: "100%", expanded: 412, extraLarge: 492 }}
      height={{
        compact: dynamicHeight,
        expanded: "calc(100vh - 16px)",
        large: "calc(100vh - 24px)"
      }}
    >
      <Messages />
      <Suggestions locked={locked} />
      <Input locked={locked} />
    </Box>
  );
}

export default observer(AgentClient);

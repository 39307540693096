import { useEffect, useState } from "react";
import { ButtonBase, Collapse, Stack } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";
import ToolTip from "../../Tooltip";

function Suggestions({ mobile = false, locked }) {
  const [suggestions, setSuggestions] = useState([]);
  const { analytics, agent, reader } = useStore();
  const disabled = agent.running || agent.loaded === false;
  const lastMessageIsTldr =
    suggestions.length === 5 &&
    agent.history
      .slice(0, 2)
      .some(({ text = "" }) =>
        text.startsWith(`Here's a tldr on **${reader.paperNode?.title}`)
      );

  useEffect(() => {
    if (agent.loaded && agent.paperHasBeenRead && reader.paperNode) {
      const { title } = reader.paperNode;

      setSuggestions([
        [
          "summarize",
          `Give me a tldr for the paper "${title}"`,
          "Get a short summary of this paper"
        ],
        [
          "insights",
          `What are the top new ideas / insights presented in the paper "${title}"`,
          "Get top insights"
        ],
        [
          "applications",
          `For the paper "${title}", what are the practical applications where this can be immediately used? What are the real world limitations?`,
          "Understand practical applications"
        ],
        [
          "limitations",
          `For the paper "${title}", what are the limitations presented? Help me understand numerically`,
          "See limits of the work"
        ],
        [
          "compare vs alternatives",
          `For the paper "${title}", how do techniques presented compare against alternatives? Help me understand the numerical diff`,
          "Compare this work against alternatives"
        ]
      ]);

      return () => setSuggestions([]);
    }
  }, [agent.loaded, agent.paperHasBeenRead, reader.paperNode]);

  useEffect(() => {
    if (lastMessageIsTldr) {
      setSuggestions(suggestions => {
        suggestions.shift();

        return [...suggestions];
      });
    }
  }, [agent, lastMessageIsTldr]);

  return (
    <Collapse mountOnEnter unmountOnExit in={suggestions.length !== 0}>
      <Stack
        spacing={0.5}
        direction="row"
        alignItems="center"
        flexWrap="nowrap"
        py={1}
        height={mobile && suggestions.length === 0 ? 8 : 48}
      >
        {suggestions.map(([label, command, tooltip]) => (
          <ToolTip key={label} title={tooltip}>
            <ButtonBase
              disabled={disabled}
              onClick={() => {
                if (locked) {
                  return locked();
                }

                analytics.track.event("Agent Suggestion", { command: label });
                agent.chat(command);

                setSuggestions(
                  suggestions.filter(suggestion => suggestion[0] !== label)
                );
              }}
              sx={theme => ({
                px: 1,
                py: 0.25,
                border: 1,
                typography: "labelSm",
                height: { compact: "100%", expanded: "unset" },
                color: disabled
                  ? theme.palette.alpha(
                      theme.palette.primary.on.container,
                      0.38
                    )
                  : "primary.on.container",
                bgcolor: disabled
                  ? theme.palette.alpha(
                      theme.palette.primary.on.container,
                      0.12
                    )
                  : "primary.container",
                borderRadius: theme.shape.sm,
                borderColor: disabled
                  ? theme.palette.alpha(
                      theme.palette.primary.on.container,
                      0.12
                    )
                  : "outline.color",
                transition: theme.transitions.create("all"),
                ...theme.motion.transition[disabled ? "exit" : "enter"][
                  disabled ? "temp" : "perm"
                ],
                "&:hover": {
                  boxShadow: theme.elevation[1]
                }
              })}
            >
              {label}
            </ButtonBase>
          </ToolTip>
        ))}
      </Stack>
    </Collapse>
  );
}

export default observer(Suggestions);

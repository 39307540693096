"use client";
import { ButtonBase } from "@mui/material";

import ToolTip from "../Tooltip";

export default function ButtonToggle({
  selected,
  OnIcon,
  OffIcon,
  size = "medium",
  tooltip,
  sx = {},
  ...props
}) {
  const Icon = selected ? OnIcon : OffIcon;

  return (
    <ToolTip title={tooltip}>
      <ButtonBase
        sx={theme => ({
          borderRadius: theme.shape.round,
          width: size === "small" ? 32 : 40,
          height: size === "small" ? 32 : 40,
          bgcolor: selected ? "primary.color" : "surface.container.highest",
          transition: theme.transitions.create("all"),
          "&:hover": {
            backgroundImage: `linear-gradient(${theme.palette.alpha(
              selected
                ? theme.palette.primary.on.color
                : theme.palette.primary.color,
              0.08
            )}, ${theme.palette.alpha(
              selected
                ? theme.palette.primary.on.color
                : theme.palette.primary.color,
              0.08
            )})`,
            "& svg": {
              color: selected ? "primary.on.color" : "primary.color"
            }
          },
          ...sx
        })}
        {...props}
      >
        <Icon
          sx={() => ({
            width: size === "small" ? 16 : 24,
            height: size === "small" ? 16 : 24,
            color: selected ? "primary.on.color" : "primary.color"
          })}
        />
      </ButtonBase>
    </ToolTip>
  );
}

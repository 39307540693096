import { Box } from "@mui/material";

import { graph } from "../../service/graph-req";
import Client from "./Client";
import ClientHooks from "./ClientHooks";
import { textRank } from "../../service/mobx/utilities";
import { convertToID } from "../../service/graph";

export default async function Agent({ publisher, paperID }) {
  const docsPages = publisher !== undefined && paperID !== undefined;
  const trendingMd = docsPages ? undefined : await getTrendingMd();
  const tldrMd = docsPages ? await getTldrMd(publisher, paperID) : undefined;

  return (
    <>
      <Client />
      <ClientHooks tldrMd={tldrMd} trendingMd={trendingMd} />
    </>
  );
}

async function getTrendingMd() {
  const papers = await graph("trendingPapers", { limit: 3 });

  if (papers.length !== 0) {
    let markdown = "Here's what's trending today\n";

    for (let i = 0, j = papers.length; i < j; i++) {
      var { publisher, paperID, title, summary, summaries } = papers[i];

      markdown += `- [${title}](/docs/${publisher}/${paperID}/paper)\n`;
      markdown += `  - ${textRank(summaries[0]?.summary ?? summary, 2)}\n`;
    }

    return { markdown, papers };
  }
}
export async function getTldrMd(publisher, paperID) {
  const [paper] = await graph("paperMeta", {
    id: convertToID({ publisher, paperID })
  });
  const tldr = paper?.summaries?.[0]?.summary;

  if (tldr) {
    let markdown = `Here's a tldr on **${paper.title}**`;

    markdown += `\n\n${tldr}\n\n`;
    markdown += "What would you like to know?";

    return markdown;
  }
}

export const EmptyState = () => (
  <Box
    height="inherit"
    width={{ compact: 360, large: 412, extraLarge: 412 + 80 }}
  />
);

"use client";
import { useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { Box, Stack, Typography } from "@mui/material";
import {
  DeleteOutline as DeleteIcon,
  DeleteSweep as ClearIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../../service/mobx";
import ToolTip from "../../Tooltip";
import LongPressIconButton from "../../Button/LongPress";

const Bytez = dynamic(() => import("./Bytez"));
const User = dynamic(() => import("./User"));
const Sources = dynamic(() => import("./Sources"));
const Citation = dynamic(() => import("./Citation"));

function ChatMessages() {
  const { agent, device } = useStore();
  const ref = useRef();
  const lastMessage = agent.history[0]?.id;

  useEffect(() => {
    if (lastMessage) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
    }
  }, [lastMessage]);

  return (
    <Stack
      useFlexGap
      spacing={6}
      flexGrow={1}
      height="100%"
      overflow="auto"
      direction="column-reverse"
      sx={theme => theme.fadeEdge("top", device.isPhone ? 99 : 98)}
    >
      <span ref={ref} />
      {agent.history.map(({ id, role, text, citations, sources, step }) => (
        <Options key={id} messageId={id}>
          {role === "user" ? (
            <User text={text} />
          ) : (
            <>
              <Bytez text={text} step={step} />
              {citations?.length ? (
                <Citation citations={citations} sources={sources} />
              ) : sources?.length ? (
                <Sources sources={sources} />
              ) : null}
            </>
          )}
        </Options>
      ))}
    </Stack>
  );
}

export default observer(ChatMessages);
export const AvatarGroup = ({ Avatar, name }) => (
  <Stack spacing={1.5} direction="row" alignItems="center">
    <Avatar />
    <Typography variant="labelLgProminent" color="surface.on.variant">
      {name}
    </Typography>
  </Stack>
);

function Options({ messageId, children }) {
  const { agent } = useStore();
  const actions = [
    {
      Icon: DeleteIcon,
      tooltip: "Hold to delete this message",
      onClick: () => agent.message.delete(messageId)
    },
    {
      Icon: ClearIcon,
      tooltip: "Hold to clear your entire conversation",
      onClick: agent.message.deleteAll
    }
  ];

  return (
    <Box
      sx={{
        "&:hover .options": {
          opacity: 1
        }
      }}
    >
      <Stack
        spacing={0.5}
        height={0}
        width="100%"
        className="options"
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
        sx={theme => ({
          opacity: 0,
          transition: theme.transitions.create("all", { delay: 1e3 })
        })}
      >
        {actions.map(({ tooltip, Icon, onClick }) => (
          <ToolTip key={tooltip} placement="top" title={tooltip}>
            <LongPressIconButton
              aria-label={tooltip}
              disabled={agent.running}
              onLongPress={onClick}
              sx={theme => ({
                bgcolor: theme.palette.alpha(
                  theme.palette.tertiary.container,
                  0.4
                ),
                "&:hover": {
                  bgcolor: "tertiary.container"
                }
              })}
            >
              <Icon sx={{ color: "tertiary.color" }} />
            </LongPressIconButton>
          </ToolTip>
        ))}
      </Stack>
      {children}
    </Box>
  );
}

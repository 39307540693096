import { useState, useRef, useEffect, useCallback } from "react";
import { IconButton, CircularProgress, Box } from "@mui/material";
import { Check as DoneIcon } from "@mui/icons-material";

export default function LongPressIconButton({
  onLongPress,
  holdDuration = 3e3,
  children,
  ...props
}) {
  const [progress, setProgress] = useState(0);
  const [isPressed, setIsPressed] = useState(false);
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);
  const done = progress === 100;
  const handleEnd = useCallback(() => {
    clearInterval(timerRef.current);
    setProgress(0);
    setIsPressed(false);
  }, []);
  const handleStart = useCallback(() => {
    if (props.disabled !== true) {
      handleEnd();
      setIsPressed(true);

      startTimeRef.current = Date.now();
      timerRef.current = setInterval(() => {
        const progress =
          ((Date.now() - startTimeRef.current) / holdDuration) * 100;

        // wait for the button to turn green, as there's a delay between state updates
        if (120 <= progress) {
          handleEnd();
          onLongPress?.();
        } else {
          setProgress(Math.min(progress, 100));
        }
      }, 250);
    }
  }, [handleEnd, props.disabled, holdDuration, onLongPress]);

  useEffect(() => () => clearInterval(timerRef.current), []);

  return (
    <Box
      position="relative"
      display="inline-block"
      onMouseDown={handleStart}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
      onTouchStart={handleStart}
      onTouchEnd={handleEnd}
      sx={isPressed ? { cursor: "progress" } : undefined}
    >
      <IconButton
        {...props}
        color={done ? "success" : undefined}
        sx={done ? { bgcolor: "colors.success.60" } : undefined}
      >
        {done ? <DoneIcon sx={{ color: "colors.success.95" }} /> : children}
      </IconButton>
      {isPressed ? (
        <CircularProgress
          size={48}
          thickness={3}
          value={progress}
          variant="determinate"
          color={done ? "success" : "primary"}
          sx={{ position: "absolute", top: -4, left: -4, zIndex: 1 }}
        />
      ) : null}
    </Box>
  );
}
